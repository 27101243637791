
import { defineComponent } from 'vue';
import router from '@/router';
import { PhUserCircle, PhSignOut, PhDownloadSimple } from "@phosphor-icons/vue";

export default defineComponent({
  components: {PhUserCircle, PhSignOut, PhDownloadSimple},
  props: {
    optionIconName: {
      required: true,
      type: String
    },
    optionText: {
      required: true,
      type: String
    },
    optionAriaLabel: {
      required: true,
      type: String
    },
    optionRedirectTo: {
      required: true,
      type: String
    }
  },
  setup (props) {
    const goToPage = ():void => {
      setTimeout(() => {
        router.push({name: props.optionRedirectTo});
      }, 250);
    }

    return {
      goToPage
    }
  }
})
