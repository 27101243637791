
import { defineComponent } from 'vue';

//Components
import Header from '@/components/Header.vue';
import router from '@/router';

export default defineComponent({
  components: {Header},
  setup () {
    const goBackAction = () => {
      router.back();
    }

    return {
      goBackAction
    }
  }
})
