
import { defineComponent, ref, watch, onMounted } from 'vue';
import router from '@/router';

//Composables
import { loginWithGoogle, loginWithEmail, createAnAccount, loginAnonymously } from '@/composables/auth';

//Components
import LoginButton from '@/components/login/LoginButton.vue';
import Button from '@/components/Button.vue';
import Header from '@/components/Header.vue';
import HeaderNoti from '@/components/HeaderNotification.vue';
import { RouteRecordName } from 'vue-router';

export default defineComponent({
  components: {LoginButton, Button, Header, HeaderNoti},
  setup () {
    const showView = ref<'Main' | 'ThirdyParties'  | 'EmailLogin' | 'EmailSingUp' | 'Anonymous'>('Main');
    const headerTitle = ref<string>('');

    watch(showView, (newValue) => {
      switch (newValue) {
        case 'ThirdyParties':
          headerTitle.value = 'Conectar conta';
          break;
      
        case 'EmailLogin':
          headerTitle.value = 'Login por email';
          break;

        case 'EmailSingUp':
          headerTitle.value = 'Crie sua conta';
          break;

        case 'Anonymous':
          headerTitle.value = 'Conta anônima';
          break;

        default:
          headerTitle.value = '';
          break;
      }

      headerNotiText.value = '';
      userName.value = '';
      userEmail.value = '';
      userPassword.value = '';
    });

    //Login inputs refs 
    const userName = ref<string>('');
    const userEmail = ref<string>('');
    const userPassword = ref<string>('');
    const userActionButton = ref<boolean>(false);

    //Notification refs
    const headerNotiIcon = ref<notificationHeaderIcon>('PhBellRinging');
    const headerNotiText = ref<string>('');

    const wipeHeaderNoti = (type:'Error' | 'Success'):void => {
      setTimeout(():void => {
        headerNotiText.value = '';
      }, type === 'Error' ? 5000 : 3000)
    };

    //Redirect related
    const redirectTo = ref<RouteRecordName>('app');

    const redirectUser = ():void => {
      setTimeout(() => {
        router.push({name: redirectTo.value});
      }, 5000);
    }

    //Deleting user account related
    onMounted(() => {
      if(router.currentRoute.value.query.redirecionar === 'AccViewDelete'){
        redirectTo.value = 'AccViewDelete';
      }
    })

    const googleLogin = ():void => {
      userActionButton.value = true;

      loginWithGoogle()
        .then(() => {
          headerNotiIcon.value = 'PhBellRinging';
          headerNotiText.value = 'Sucesso, vinculação concluída!';

          redirectUser();
        })
        .catch((error) => {
          headerNotiIcon.value = 'PhSealWarning';
          headerNotiText.value = 'Ocorreu um erro, tente novamente mais tarde.';

          wipeHeaderNoti('Error');
          userActionButton.value = false;
        })
    };

    const emailSingIn = ():void => {
      userActionButton.value = true;

      loginWithEmail(userEmail.value, userPassword.value)
        .then(() => {
          headerNotiIcon.value = 'PhBellRinging';
          headerNotiText.value = 'Sucesso, suas informações conferem!';

          redirectUser();
        })
        .catch((error) => {
          let msg:string;

          switch (error) {
            case 'auth/user-not-found':
              msg = 'O email informado não está cadastrado.';
              break;

            case 'auth/wrong-password':
              msg = 'Senha incorreta, tente novamente.';
              break;
          
            default:
              msg = 'Ocorreu um erro, tente novamente mais tarde.';
              console.error(error);
              break;
          }

          headerNotiIcon.value = 'PhSealWarning';
          headerNotiText.value = msg;

          wipeHeaderNoti('Error');
          userActionButton.value = false;
        })
    };

    const emailSingUp = ():void => {
      userActionButton.value = true;

      createAnAccount(userEmail.value, userPassword.value, userName.value.trim())
        .then(() => {
          headerNotiIcon.value = 'PhBellRinging';
          headerNotiText.value = 'Sucesso, sua conta foi criada!';

          redirectUser();
        })
        .catch((error) => {
          let msg:string;

          switch (error) {
            case 'auth/email-already-in-use':
              msg = 'O email informado já está cadastrado.';
              break;
            
            case 'auth/weak-password':
              msg = 'Senha fraca, tente novamente.'
              break;
          
            default:
              msg = 'Ocorreu um erro, tente novamente.';
              console.error(error);
              break;
          }

          headerNotiIcon.value = 'PhSealWarning';
          headerNotiText.value = msg;

          wipeHeaderNoti('Error');
          userActionButton.value = false;
        })
    };

    const anonymousLogin = ():void => {
      userActionButton.value = true;

      loginAnonymously()
        .then(() => {
          headerNotiIcon.value = 'PhBellRinging';
          headerNotiText.value = 'Sucesso! Entrando na sua conta anônima.';
          
          redirectUser();
        })
        .catch((error) => {
          headerNotiIcon.value = 'PhSealWarning';
          headerNotiText.value = 'Ocorreu um erro, tente novamente mais tarde.';

          wipeHeaderNoti('Error');
          userActionButton.value = false;
        })
    };

    return {
      showView,
      headerTitle,
      userName,
      userEmail,
      userPassword,
      userActionButton,
      headerNotiIcon,
      headerNotiText,
      googleLogin,
      emailSingIn,
      emailSingUp,
      anonymousLogin
    }
  }
})
