import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: {name: _ctx.optionGo}
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.optionIcon), {
        size: 29,
        color: "#333333",
        weight: "light"
      })),
      _createElementVNode("div", null, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.optionName), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.optionDescription), 1)
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}