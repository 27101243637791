
import { defineComponent, ref, onMounted, provide } from 'vue';
import { getAuth, onAuthStateChanged } from "firebase/auth";

//Stores
import userInfo from '@/store/user-info';

export default defineComponent({
  setup () {
    const auth = getAuth();
    const userData = ref<userInfo>(userInfo);

    onAuthStateChanged(auth, user => {
      if(!user){return;}

      userData.value.fullName = user.displayName;
      userData.value.email = user.email;
      userData.value.imageURL = user.photoURL;
      userData.value.isAnonymous = user.isAnonymous;
      userData.value.isEmailVerified = user.emailVerified;
      userData.value.uid = user.uid;
    })

    //PWA installation related
    const pwaPrompt = ref<BeforeInstallPromptEvent | null>(null);

    onMounted(() => {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();

        pwaPrompt.value = e as BeforeInstallPromptEvent;
      })
    })

    provide('pwaPrompt', pwaPrompt);

    return {}
  }
})
