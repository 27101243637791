
import { defineComponent } from 'vue';
import {PhGoogleLogo, PhEnvelope, PhDetective} from "@phosphor-icons/vue";

export default defineComponent({
  components: {PhGoogleLogo, PhEnvelope, PhDetective},
  props: {
    buttonTitle: {
      required: true,
      type: String
    },
    iconTitle: {
      required: true,
      type: String
    }
    },
    setup() {
        return {};
    }
})
