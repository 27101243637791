import { toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass({
    iconOnLeft: _ctx.hasIcon === 'Yes-Left',
    secondary: _ctx.buttonType === 'Secondary',
    accent: _ctx.buttonType === 'Accent'
  })
  }, [
    _createTextVNode(_toDisplayString(_ctx.buttonText) + " ", 1),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconName), { size: _ctx.iconSize }, null, 8, ["size"]))
  ], 2))
}