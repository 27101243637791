import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72b78abb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderNotification = _resolveComponent("HeaderNotification")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      "header-type": 'Secondary',
      "header-title": _ctx.headerTitle,
      onGoBack: _ctx.handleGoBackAction
    }, {
      notification: _withCtx(() => [
        (_ctx.showNotification)
          ? (_openBlock(), _createBlock(_component_HeaderNotification, {
              key: 0,
              "notification-icon": _ctx.notificationIcon,
              "notification-text": _ctx.notificationText
            }, null, 8, ["notification-icon", "notification-text"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["header-title", "onGoBack"]),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({Component}) => [
          _createVNode(_Transition, { name: "page-slide" }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ])
  ], 64))
}