
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  props: {
    messageStatus: {
      required: true,
      type: String as () => 'Loading' | 'Success' | 'Success & Empty' | 'Error'
    }
  },
  setup() {

    return {
    }
  },
})

