
import { defineComponent, onMounted, ref } from 'vue';
import { PhSealWarning, PhWarningCircle, PhBellRinging } from "@phosphor-icons/vue";

export default defineComponent({
  components: {PhSealWarning, PhWarningCircle, PhBellRinging},
  props: {
    notificationIcon: {
      required: true,
      type: String as () => notificationHeaderIcon
    },
    notificationText: {
      required: true,
      type: String
    }
  },
  emits: ['headerNotificationClick'],
  setup (props, {emit}) {

    const emitEvent = ():void => {
      emit('headerNotificationClick');
    }

    return {
      emitEvent
    }
  }
})
