
import { defineComponent, ref, Ref, onMounted, onBeforeUnmount, inject } from 'vue';

//Composables
import { addDocToList, editDocFromList } from '@/composables/data-base';

//Components
import Button from '@/components/Button.vue';

//Store
import userInfo from '@/store/user-info';

export default defineComponent({
  components: {Button},
  props: {
    modalAction: {
      required: true,
      type: String as () => 'AddItem' | 'EditItem'
    }
  },
  emits: ['dialogClosed'],
  setup (props, {emit}) {
    const modalEl = ref<HTMLDialogElement | null>(null);
    const itemName = ref<string | null>(null);
    const itemQuantity = ref<number | null>(null);
    const itemQuantityMetric = ref<quantityMetrics | null>(null);
    const itemPrice = ref<number | null>(null);

    const addButtonState = ref<'Standby' | 'Loading' | 'Disabled'>('Standby');
    const itemIdForEditAction = inject('itemId') as Ref<string | undefined>;
    const userData = ref<userInfo>(userInfo);

    const observeOpenAttribute = ():void => {
      if(!(modalEl.value instanceof HTMLDialogElement)){return}

      const el = modalEl.value;
      const observer = new MutationObserver((mutationList) => {
        for (const mutation of mutationList) {
          if(mutation.type === 'attributes' && mutation.attributeName === 'open'){
            if(!el.hasAttribute('open')){
              emit('dialogClosed');
            }
          }
        }
      });

      observer.observe(el, {attributes: true});

      onBeforeUnmount(() => {
        observer.disconnect();
      })
    }

    const closeDialog = ():void => {
      if(!(modalEl.value instanceof HTMLDialogElement)){return}

      const el = modalEl.value;
      el.close();
    }

    const addItem = ():void => {
      if(itemName.value === null || itemQuantity.value === null || itemQuantityMetric.value === null || itemPrice.value === null ){return alert('Ocorreu um erro.')}
      addButtonState.value = 'Loading';

      const itemInfo:itemInfo = {
        name: itemName.value.trim(),
        tags: {
          quantity: itemQuantity.value,
          quantityMetric: itemQuantityMetric.value,
          price: itemPrice.value
        }
      }

      addDocToList(itemInfo)
        .then(() => {closeDialog();})
        .catch((error) => {alert(error); closeDialog();})
    }

    const editItem = ():void => {
      if(itemName.value === null || itemQuantity.value === null || itemQuantityMetric.value === null || itemPrice.value === null || itemIdForEditAction.value === undefined){return alert('Ocorreu um erro.')}
      addButtonState.value = 'Loading';

      const changesObj:updateDocOnCloud = {
        name: itemName.value,
        tags: {
          quantity: itemQuantity.value,
          quantityMetric: itemQuantityMetric.value,
          price: itemPrice.value
        }
      }

      editDocFromList(itemIdForEditAction.value, changesObj)
        .then(() => {closeDialog();})
        .catch((error) => {alert(error); closeDialog();})
    }

    onMounted(() => {
      if(!(modalEl.value instanceof HTMLDialogElement)){return}

      const el = modalEl.value;
      if(props.modalAction === 'EditItem' && userData.value.userList !== null){
        const itemObj = userData.value.userList.filter(obj => {return obj.id === itemIdForEditAction.value});

        itemName.value = itemObj[0].name;
        itemQuantity.value = itemObj[0].tags.quantity;
        itemQuantityMetric.value = itemObj[0].tags.quantityMetric;
        itemPrice.value = itemObj[0].tags.price;
      }

      el.showModal();
      observeOpenAttribute();
    })

    return {
      modalEl,
      itemName,
      itemQuantity,
      itemQuantityMetric,
      itemPrice,
      addButtonState,
      closeDialog,
      addItem,
      editItem
    }
  }
})
