import { toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be52ba3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    "aria-label": _ctx.optionAriaLabel,
    title: _ctx.optionAriaLabel,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToPage && _ctx.goToPage(...args)))
  }, [
    _createTextVNode(_toDisplayString(_ctx.optionText) + " ", 1),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.optionIconName), {
      size: 29,
      color: "#333333",
      weight: "light"
    }))
  ], 8, _hoisted_1))
}