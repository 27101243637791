import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c8d21d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "userInfo" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { id: "accountOptions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhUserCircle = _resolveComponent("PhUserCircle")!
  const _component_PhDetective = _resolveComponent("PhDetective")!
  const _component_AccountLinkOptions = _resolveComponent("AccountLinkOptions")!

  return (_openBlock(), _createElementBlock("article", null, [
    _createElementVNode("section", _hoisted_1, [
      (_ctx.accountImageToShow === 'userProfilePicture')
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.userProfilePicture !== null ? _ctx.userProfilePicture : '',
            alt: "Foto de perfil do usuário.",
            height: "128",
            width: "128"
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.accountImageToShow === 'userIcon')
        ? (_openBlock(), _createBlock(_component_PhUserCircle, {
            key: 1,
            size: 128,
            color: "'#333333'",
            weight: 'light'
          }))
        : _createCommentVNode("", true),
      (_ctx.accountImageToShow === 'anonymousIcon')
        ? (_openBlock(), _createBlock(_component_PhDetective, {
            key: 2,
            size: 128,
            color: "#333333",
            weight: "light"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.nameToShow), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.identificationToShow), 1),
        (_ctx.memberSince !== '')
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, "Membro desde " + _toDisplayString(_ctx.memberSince), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createVNode(_component_AccountLinkOptions, {
        "option-icon": 'PhInfo',
        "option-name": 'Sobre',
        "option-description": 'Sobre o aplicativo.',
        "option-go": 'AccViewAbout'
      }, null, 8, ["option-description"]),
      false
        ? (_openBlock(), _createBlock(_component_AccountLinkOptions, {
            key: 0,
            "option-icon": 'PhSparkle',
            "option-name": 'Novidades',
            "option-description": 'Veja as últimas atualizações.',
            "option-go": 'AccViewAbout'
          }, null, 8, ["option-description"]))
        : _createCommentVNode("", true),
      (_ctx.showLinkAccountOption)
        ? (_openBlock(), _createBlock(_component_AccountLinkOptions, {
            key: 1,
            "option-icon": 'PhLink',
            "option-name": 'Vincular conta',
            "option-description": 'Crie uma conta permanente.',
            "option-go": 'AccViewAbout'
          }, null, 8, ["option-description"]))
        : _createCommentVNode("", true),
      (_ctx.showVerifyEmailOption)
        ? (_openBlock(), _createBlock(_component_AccountLinkOptions, {
            key: 2,
            "option-icon": 'PhEnvelope',
            "option-name": 'Verificar email',
            "option-description": 'Confirme sua conta.',
            "option-go": 'AccViewVerifyEmail'
          }, null, 8, ["option-description"]))
        : _createCommentVNode("", true),
      (_ctx.showPwaOption)
        ? (_openBlock(), _createBlock(_component_AccountLinkOptions, {
            key: 3,
            "option-icon": 'PhDownloadSimple',
            "option-name": 'Adicionar à tela inicial',
            "option-description": 'Tenha acesso instantâneo.',
            "option-go": 'AccViewPwa'
          }, null, 8, ["option-description"]))
        : _createCommentVNode("", true),
      _createVNode(_component_AccountLinkOptions, {
        "option-icon": 'PhShieldCheck',
        "option-name": 'Política de privacidade',
        "option-description": 'Revise a política de privacidade do aplicativo.',
        "option-go": 'pp'
      }, null, 8, ["option-description"]),
      _createVNode(_component_AccountLinkOptions, {
        "option-icon": 'PhTrashSimple',
        "option-name": 'Deletar conta',
        "option-description": 'Solicite a eliminação da sua conta.',
        "option-go": 'AccViewDelete'
      }, null, 8, ["option-description"])
    ])
  ]))
}