
import { defineComponent } from 'vue';
import { PhInfo, PhSparkle, PhLink, PhEnvelope, PhDownloadSimple, PhShieldCheck, PhTrashSimple } from "@phosphor-icons/vue";

export default defineComponent({
  components: {PhInfo, PhSparkle, PhLink, PhEnvelope, PhDownloadSimple, PhShieldCheck, PhTrashSimple},
  props: {
    optionGo: {
      required: true,
      type: String
    },
    optionIcon: {
      required: true,
      type: String
    },
    optionName: {
      required: true,
      type: String
    },
    optionDescription: {
      required: true,
      type: String
    }
  },
  setup () {
    

    return {}
  }
})
