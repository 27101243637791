
import { defineComponent, ref, Ref, onMounted, watch, provide, inject } from 'vue';
import { PhShoppingCart } from "@phosphor-icons/vue";

//Composables
import { isUserEmailVerified, isUserAnonymous } from '@/composables/auth';
import { getUserGroceriesList } from '@/composables/data-base';

//Components
import Header from '@/components/Header.vue';
import OptionsMenu from '@/components/app/OptionsMenu.vue';
import OptionsMenuItem from '@/components/app/OptionsMenuItem.vue';
import ListCompanion from '@/components/app/ListCompanion.vue';
import HeaderNotification from '@/components/HeaderNotification.vue';
import Button from '@/components/Button.vue';
import WelcomeMessage from '@/components/app/WelcomeMessage.vue';
import AddItemModal from '@/components/app/AddItemModal.vue';
import ListItem from '@/components/app/ListItem.vue';

//Stores
import userInfo from '@/store/user-info';

export default defineComponent({
  components: {Header, OptionsMenu, OptionsMenuItem, ListCompanion, HeaderNotification, WelcomeMessage, Button, AddItemModal, ListItem, PhShoppingCart},
  setup () {
    const showOptionsMenu = ref<boolean>(false);
    const appStatus = ref<'Loading' | 'Success' | 'Success & Empty' | 'Error'>('Loading');
    const showPWAInstallButton = ref<boolean>(false);
    const showItemModal = ref<boolean>(false);
    const modalAction = ref<'AddItem' | 'EditItem'>('AddItem');
    const itemIdForEditAction = ref<string>('');
    const userData = ref<userInfo>(userInfo);

    //Notification related
    const showNotification = ref<boolean>(false);
    const notificationIcon = ref<notificationHeaderIcon>('PhBellRinging');
    const notificationText = ref<string>('');

    onMounted(() => {
      console.log('Recolhendo informações...');
      getUserGroceriesList()
        .then(() => {
          if(userData.value.userList === null || userData.value.userList.length === 0){
            appStatus.value = 'Success & Empty';
          } else {
            appStatus.value = 'Success';
          }

          console.log('Sucesso!');
        })
        .catch((error) => {
          appStatus.value = 'Error';
          return console.error('Ocorreu um erro.', error)
      })

      //PWA installation reletaed
      if(!(window.matchMedia('(display-mode: standalone)').matches)){
        showPWAInstallButton.value = true;
      };

      //Notification check chain
      if(isUserAnonymous() === true){
        showNotification.value = true;
        notificationIcon.value = 'PhWarningCircle';
        notificationText.value = 'Você está utilizando uma conta anônima.';

      } else if(isUserEmailVerified() === false){
        showNotification.value = true;
        notificationIcon.value = 'PhWarningCircle';
        notificationText.value = 'Verifique sua conta, confira a sua caixa de entrada!';
      }
    })

    watch(userData.value, (newValue) => {
      if(newValue.userList === null){return}
      else if (newValue.userList.length > 0){
        appStatus.value = 'Success';
      }
      else {
        //Wait for the animation of the last ListItem.
        setTimeout(() => {
          appStatus.value = 'Success & Empty';
        }, 500);
      };
    })

    const addItemAction = ():void => {
      modalAction.value = 'AddItem';
      showItemModal.value = true;
    }

    const editItemAction = (itemId:string):void => {
      modalAction.value = 'EditItem';
      showItemModal.value = true;

      itemIdForEditAction.value = itemId;
    }

    const dialogClosed = ():void => {
      showItemModal.value = false;
    }

    provide('itemId', itemIdForEditAction);
    provide('modalStatus', showItemModal);
    provide('appStatus', appStatus);

    return {
      showOptionsMenu,
      appStatus,
      showPWAInstallButton,
      showNotification,
      notificationIcon,
      notificationText,
      showItemModal,
      modalAction,
      userData,
      addItemAction,
      editItemAction,
      dialogClosed
    }
  }
})
