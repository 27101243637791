import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
    error: _ctx.notificationIcon === 'PhSealWarning',
    alert: _ctx.notificationIcon === 'PhWarningCircle'
  }),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitEvent && _ctx.emitEvent(...args)))
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.notificationIcon), {
      size: 16,
      weight: "regular"
    })),
    _createElementVNode("p", null, _toDisplayString(_ctx.notificationText), 1)
  ], 2))
}