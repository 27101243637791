import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconTitle), {
      weight: "regular",
      size: "24"
    })),
    _createTextVNode(" " + _toDisplayString(_ctx.buttonTitle), 1)
  ]))
}