
import { defineComponent, ref, Ref, inject, onMounted } from 'vue';

//Components
import Button from '@/components/Button.vue';

export default defineComponent({
  components: {Button},
  setup () {
    //AccountView parent related
    const headerTitle = inject('headerTitle') as Ref<string>;
    const goBackAction = inject('goBackAction') as Ref<'goBackToApp' | 'goBackToMainView'>;

    headerTitle.value = 'Adiconar à tela inicial';
    goBackAction.value = 'goBackToMainView';

    //Notification related
    const notificationIcon = inject('notificationIcon') as Ref<notificationHeaderIcon>;
    const notificationText = inject('notificationText') as Ref<string>;

    //PWA installation related
    const disableButton = ref<boolean>(false);
    const pwaPrompt = inject('pwaPrompt') as Ref<BeforeInstallPromptEvent>;

    const handleInstallation = async ():Promise<void> => {
      try {
        disableButton.value = true;

        pwaPrompt.value.prompt();

        const { outcome } = await pwaPrompt.value.userChoice;

        if(outcome === 'accepted'){
          notificationIcon.value = 'PhBellRinging';
          notificationText.value = 'Sucesso, agora sua lista estará sempre com você!';
        } else {
          disableButton.value = false;
          notificationIcon.value = 'PhWarningCircle';
          notificationText.value = 'Você recusou a instalação, mas pode tentar novamente.';
        }
      } catch (error) {
        console.error(error);
        disableButton.value = true;

        notificationIcon.value = 'PhSealWarning';
        notificationText.value = 'Ocorreu um erro inesperado.';
      }
    }

    return {
      handleInstallation,
      disableButton
    }
  }
})
