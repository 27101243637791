import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Transition as _Transition, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, TransitionGroup as _TransitionGroup, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderNotification = _resolveComponent("HeaderNotification")!
  const _component_ListCompanion = _resolveComponent("ListCompanion")!
  const _component_Header = _resolveComponent("Header")!
  const _component_OptionsMenuItem = _resolveComponent("OptionsMenuItem")!
  const _component_OptionsMenu = _resolveComponent("OptionsMenu")!
  const _component_WelcomeMessage = _resolveComponent("WelcomeMessage")!
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_AddItemModal = _resolveComponent("AddItemModal")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      "header-type": 'Main',
      onToggleOptionsMenu: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showOptionsMenu = !_ctx.showOptionsMenu))
    }, {
      notification: _withCtx(() => [
        (_ctx.showNotification)
          ? (_openBlock(), _createBlock(_component_HeaderNotification, {
              key: 0,
              "notification-icon": _ctx.notificationIcon,
              "notification-text": _ctx.notificationText
            }, null, 8, ["notification-icon", "notification-text"]))
          : _createCommentVNode("", true)
      ]),
      listCompanion: _withCtx(() => [
        _createVNode(_component_ListCompanion)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "optionsMenu" }, {
      default: _withCtx(() => [
        (_ctx.showOptionsMenu)
          ? (_openBlock(), _createBlock(_component_OptionsMenu, { key: 0 }, {
              default: _withCtx(() => [
                (_ctx.showPWAInstallButton)
                  ? (_openBlock(), _createBlock(_component_OptionsMenuItem, {
                      key: 0,
                      "option-icon-name": 'PhDownloadSimple',
                      "option-text": 'Adicionar à tela inicial',
                      "option-aria-label": 'Adicionar lista de compras à tela incial do dispositivo',
                      "option-redirect-to": 'AccViewPwa'
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("main", null, [
      _createVNode(_Transition, { name: "faded-appear" }, {
        default: _withCtx(() => [
          (_ctx.appStatus !== 'Success')
            ? (_openBlock(), _createBlock(_component_WelcomeMessage, {
                key: 0,
                id: "welcomeMessage",
                "message-status": _ctx.appStatus
              }, null, 8, ["message-status"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _withDirectives(_createVNode(_TransitionGroup, {
        tag: "ul",
        name: "listItems",
        id: "list"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userData.userList, (item) => {
            return (_openBlock(), _createBlock(_component_ListItem, {
              key: item.id,
              "item-object": item,
              onEditItem: ($event: any) => (_ctx.editItemAction(item.id))
            }, null, 8, ["item-object", "onEditItem"]))
          }), 128))
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.appStatus === 'Success']
      ]),
      _createVNode(_Transition, { name: "faded-appear" }, {
        default: _withCtx(() => [
          (_ctx.showItemModal)
            ? (_openBlock(), _createBlock(_component_AddItemModal, {
                key: 0,
                ref: "addItemModalEl",
                "modal-action": _ctx.modalAction,
                onDialogClosed: _ctx.dialogClosed
              }, null, 8, ["modal-action", "onDialogClosed"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_Button, {
        id: "addItem",
        disabled: _ctx.appStatus !== 'Success' && _ctx.appStatus !== 'Success & Empty',
        "button-text": 'Adicionar item',
        "has-icon": 'Yes-Right',
        "icon-name": 'PhShoppingCart',
        "icon-weight": 'Light',
        "icon-size": 20,
        onClick: _ctx.addItemAction
      }, null, 8, ["disabled", "onClick"])
    ])
  ], 64))
}