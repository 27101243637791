
import { defineComponent } from 'vue';
import { PhShoppingCart, PhCheckCircle, PhArrowCircleRight } from "@phosphor-icons/vue";

export default defineComponent({
  components: {PhShoppingCart, PhCheckCircle, PhArrowCircleRight},
  props: {
    buttonText: {
      required: true,
      type: String
    },
    buttonType: {
      required: false,
      type: String as () => 'Primary' | 'Secondary' | 'Accent'
    },
    hasIcon: {
      required: true,
      type: String as () => 'Yes-Right' | 'Yes-Left' | 'No'
    },
    iconName: {
      required: false,
      type: String
    },
    iconSize: {
      required: false,
      type: Number
    },
    iconWeight: {
      required: false,
      type: String as () => 'Thin' | 'Light' | 'Regular' | 'Bold' | 'Fill'
    },
    iconColor: {
      required: false,
      type: String
    }
  },
  setup (props) {
    

    return {}
  }
})
