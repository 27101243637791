
import { defineComponent, ref, watch } from 'vue';
import {PhUserCircleGear, PhArrowCircleLeft} from "@phosphor-icons/vue";

//Stores
import userInfo from '@/store/user-info';

export default defineComponent({
  components: {PhUserCircleGear, PhArrowCircleLeft},
  props: {
    headerType: {
      required: true,
      type: String as () => 'Main' | 'Secondary'
    },
    headerTitle: {
      required: false,
      type: String
    },
    fallbackComponent: {
      required: false,
      type: String
    }
  },
  emits: ['goBack', 'toggleOptionsMenu'],
  setup (props, {emit}) {
    const userData = ref<userInfo>(userInfo);
    const showProfilePic = ref<boolean>(false);
    const userProfilePic = ref<string>('');
    const showActiveStatus = ref<boolean>(false);

    if(userData.value.imageURL !== null){
      showProfilePic.value = true;
      userProfilePic.value = userData.value.imageURL;
    }

    const emitGoBack = ():void => {
      emit('goBack');
    }

    const emitToggleOptionsMenu = ():void => {
      showActiveStatus.value = !showActiveStatus.value;
      emit('toggleOptionsMenu');
    }

    return {
      showProfilePic,
      userProfilePic,
      emitGoBack,
      emitToggleOptionsMenu,
      showActiveStatus
    }
  }
})
