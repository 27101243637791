
import { defineComponent, ref, Ref, inject } from 'vue';
import { PhArrowSquareOut, PhInfo } from "@phosphor-icons/vue";

export default defineComponent({
  components: {PhArrowSquareOut, PhInfo},
  setup () {
    const headerTitle = inject('headerTitle') as Ref<string>;
    const goBackAction = inject('goBackAction') as Ref<'goBackToApp' | 'goBackToMainView'>;

    headerTitle.value = 'Sobre';
    goBackAction.value = 'goBackToMainView';

    return {}
  }
})
