
import { defineComponent, ref, onMounted } from 'vue';

//Composables
import { singUserOut } from '@/composables/auth';

//Components
import OptionsMenuItems from './OptionsMenuItem.vue';

export default defineComponent({
  components: {OptionsMenuItems},
  setup () {
    const optionsMenuEl = ref<HTMLDialogElement | null>(null);

    onMounted(() => {
      if(!(optionsMenuEl.value instanceof HTMLElement)){return};

      const el = optionsMenuEl.value;
      el.show();
    });

    const handleSingOut = ():void => {
      singUserOut();
    }

    return {
      optionsMenuEl,
      handleSingOut
    }
  }
})
