
import { defineComponent, ref, inject, Ref, watch } from 'vue';
import Button from '@/components/Button.vue';

//Composables
import { deleteDocFromList } from '@/composables/data-base';

export default defineComponent({
  components: {Button},
  props: {
    itemObject: {
      required: true,
      type: Object as () => itemInfoClient
    }
  },
  emits: ['editItem'],
  setup (props, ctx) {
    const elementStatus = ref<'Standby' | 'Edit'>('Standby');
    const buttonStatus = ref<'Standby' | 'Loading'>('Standby');
    const modalStatus = inject('modalStatus') as Ref<boolean | undefined>;

    const deleteItem = (event:Event, itemId:string):void => {
      event.stopPropagation();
      buttonStatus.value = 'Loading';

      deleteDocFromList(itemId)
        .catch(() => {
          buttonStatus.value = 'Standby';
        });
    }

    const editItem = (event:Event, newItemInfo:string):void => {
      event.stopPropagation();

      if(elementStatus.value === 'Edit'){elementStatus.value = 'Standby'; return;}
      elementStatus.value = 'Edit';
      
      ctx.emit('editItem');
    }

    watch(modalStatus, (newValue) => {
      if(newValue === false){
        elementStatus.value = 'Standby';
      }
    })

    return {
      deleteItem,
      editItem, 
      elementStatus,
      buttonStatus
    }
  }
})
