
import { defineComponent, ref, provide, watch } from 'vue';
import router from '@/router';

//Components
import Header from '@/components/Header.vue';
import HeaderNotification from '@/components/HeaderNotification.vue';
import { onBeforeRouteUpdate } from 'vue-router';

export default defineComponent({
  components: {Header, HeaderNotification},
  setup () {
    //AccountView related
    const headerTitle = ref<string>('Conta');
    const goBackAction = ref<'goBackToApp' | 'goBackToMainView'>('goBackToApp');
    provide('goBackAction', goBackAction);
    provide('headerTitle', headerTitle);

    const handleGoBackAction = ():void => {
      if(goBackAction.value === 'goBackToApp'){
        router.push('/');
      } else {
        router.push({name: 'AccViewMain'});
      }
    }

    //Notification related
    const showNotification = ref<boolean>(false);
    const notificationIcon = ref<notificationHeaderIcon>('PhBellRinging');
    const notificationText = ref<string>('');

    const wipeNotification = (pageHeader:string, timeDelay:string):void => {
      setTimeout(() => {
        if(headerTitle.value === 'Verificar email'){
          notificationText.value = '';
        } else {return}
      }, 5000);
    }

    provide('notificationIcon', notificationIcon);
    provide('notificationText', notificationText);
    provide('notificationWipeFunction', wipeNotification);

    watch(notificationText, (newValue) => {
      if(newValue !== ''){
        showNotification.value = true;
      } else {
        showNotification.value = false;
      }
    })

    onBeforeRouteUpdate(() => {
      notificationText.value = '';
    })

    return {
      headerTitle,
      handleGoBackAction,
      showNotification,
      notificationIcon,
      notificationText
    }
  }
})
