import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-199af093"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "itemName" }
const _hoisted_2 = { class: "tags" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["item", {
    editItem: _ctx.elementStatus === 'Edit'
  }]),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editItem($event, 'oi')))
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.itemObject.name), 1),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("p", null, _toDisplayString(_ctx.itemObject.tags.quantity) + " " + _toDisplayString(_ctx.itemObject.tags.quantityMetric), 1),
        _createElementVNode("p", null, _toDisplayString(Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL', minimumFractionDigits:2}).format(_ctx.itemObject.tags.price)), 1)
      ])
    ]),
    _createVNode(_component_Button, {
      "aria-label": "Remover item",
      disabled: _ctx.buttonStatus === 'Loading',
      "button-text": '',
      "button-type": 'Accent',
      "has-icon": 'Yes-Right',
      "icon-name": 'PhCheckCircle',
      "icon-size": 24,
      "icon-color": '#333333',
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteItem($event, _ctx.itemObject.id)))
    }, null, 8, ["disabled"])
  ], 2))
}