
import { defineComponent, ref, Ref, inject } from 'vue';

//Composables
import { getUserObject, deleteUserAccount } from '@/composables/auth';
import { deleteUserCollection } from '@/composables/data-base';

//Components
import Button from '@/components/Button.vue';
import router from '@/router';

export default defineComponent({
  components: {Button},
  setup () {
    //Related to AccountView.vue
    const headerTitle = inject('headerTitle') as Ref<string>;
    const goBackAction = inject('goBackAction') as Ref<'goBackToApp' | 'goBackToMainView'>;

    headerTitle.value = 'Deletar conta';
    goBackAction.value = 'goBackToMainView';

    //Notification related
    const notificationIcon = inject('notificationIcon') as Ref<notificationHeaderIcon>;
    const notificationText = inject('notificationText') as Ref<string>;

    //Deleting account related
    const handleReauthentication = ():void => {
      router.push({name: 'login', query: {redirecionar: 'AccViewDelete'}})
    }

    const disableButtons = ref<boolean>(false);

    const handleDeleteButton = ():void => {
      disableButtons.value = true;

      getUserObject()
        .then(async (res) => {
          if(res === null){
            notificationIcon.value = 'PhSealWarning';
            notificationText.value = 'Ocorreu um erro. Tente fazer login novamente.';

            return;
          }

          await deleteUserCollection(res.uid)
            .then(() => {
              deleteUserAccount()
              .then(() => {
                notificationIcon.value = 'PhBellRinging';
                notificationText.value = 'Foi uma boa caminhada, até a próxima!';

                setTimeout(() => {
                  router.push({name: 'login'});
                }, 5000);
              })
              .catch((error) => {
                console.error(error);
                disableButtons.value = false;

                notificationIcon.value = 'PhSealWarning';
                notificationText.value = 'Ocorreu um erro. Tente fazer login novamente.';
              })
            })
            .catch((error) => {
              if(error = 'delete-user-list'){
                notificationIcon.value = 'PhSealWarning';
                notificationText.value = 'Primeiro, remova todos os itens da sua lista de compras.';
              }
            })
        })
    }

    return {
      disableButtons,
      handleReauthentication,
      handleDeleteButton
    }
  }
})
