import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d24b26c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "optionsMenuEl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OptionsMenuItems = _resolveComponent("OptionsMenuItems")!

  return (_openBlock(), _createElementBlock("dialog", _hoisted_1, [
    _createVNode(_component_OptionsMenuItems, {
      "option-icon-name": 'PhUserCircle',
      "option-text": 'Conta',
      "option-aria-label": 'Ir para conta do usuário',
      "option-redirect-to": 'AccViewMain'
    }),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createVNode(_component_OptionsMenuItems, {
      "option-icon-name": 'PhSignOut',
      "option-text": 'Sair',
      "option-aria-label": 'Sair da conta e volta à tela de login',
      "option-redirect-to": 'login',
      onClick: _ctx.handleSingOut
    }, null, 8, ["onClick"])
  ], 512))
}