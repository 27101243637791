import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhHandCoins = _resolveComponent("PhHandCoins")!
  const _component_PhCirclesFour = _resolveComponent("PhCirclesFour")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
    loading: _ctx.appStatus === 'Loading'
  })
  }, [
    _createElementVNode("section", null, [
      _createVNode(_component_PhHandCoins, {
        size: 20,
        color: "#707070",
        weight: "light"
      }),
      _createElementVNode("span", null, "Total: R$ " + _toDisplayString(_ctx.totalCost), 1)
    ]),
    _createElementVNode("section", null, [
      _createVNode(_component_PhCirclesFour, {
        size: 20,
        color: "#707070",
        weight: "light"
      }),
      _createElementVNode("span", null, _toDisplayString(_ctx.totalItems) + " " + _toDisplayString(_ctx.styledItemsString) + " na lista", 1)
    ])
  ], 2))
}