
import { defineComponent, ref, Ref, inject, onBeforeUnmount, onMounted } from 'vue';
import { PhUserCircle, PhDetective } from "@phosphor-icons/vue";

//Compsables
import { getUserObject } from '@/composables/auth';

//Composables
import { isUserAnonymous, isUserEmailVerified } from '@/composables/auth';

//Components
import AccountLinkOptions from './AccountLinkOptions.vue';

//Stores
import userInfo from '@/store/user-info';

export default defineComponent({
  components: {AccountLinkOptions, PhUserCircle, PhDetective},
  setup () {
    //Related to AccountView.vue
    const headerTitle = inject('headerTitle') as Ref<string>;
    const goBackAction = inject('goBackAction') as Ref<'goBackToApp' | 'goBackToMainView'>;

    headerTitle.value = 'Conta';
    goBackAction.value = 'goBackToApp';

    //Notification related
    const notificationIcon = inject('notificationIcon') as Ref<notificationHeaderIcon>;
    const notificationText = inject('notificationText') as Ref<string>;

    //Notification check chain
    onMounted(() => {
      if(isUserAnonymous() === true){
        notificationIcon.value = 'PhWarningCircle';
        notificationText.value = 'Você está utilizando uma conta anônima.';
      } else if(isUserEmailVerified() === false){
        notificationIcon.value = 'PhWarningCircle';
        notificationText.value = 'Verifique sua conta, confira a sua caixa de entrada!';
      }
    })

    //Related to #userInfo
    const userData = ref<userInfo>(userInfo);
    const accountImageToShow = ref<'userProfilePicture' | 'userIcon' | 'anonymousIcon'>('userIcon');
    const userProfilePicture = userData.value.imageURL;
    const nameToShow = ref<string>('');
    const identificationToShow = ref<string>('');
    const memberSince = ref<string>('');

    onMounted(async () => {
      if(userData.value.isAnonymous){
        accountImageToShow.value = 'anonymousIcon';
        nameToShow.value = 'Usuário anônimo';
        identificationToShow.value = userData.value.uid as NonNullable<null>;
        
      } else if (userData.value.imageURL !== null){
        
        accountImageToShow.value = 'userProfilePicture';
        nameToShow.value = userData.value.fullName as NonNullable<null>;
        identificationToShow.value = userData.value.email as NonNullable<null>;
      } else {
        nameToShow.value = userData.value.fullName as NonNullable<null>;
        identificationToShow.value = userData.value.email as NonNullable<null>;
      }
        const userAccCreation = await getUserObject().then((res) => {return res})

        const date = new Intl.DateTimeFormat('pt-BR').format(new Date(userAccCreation?.metadata.creationTime as NonNullable<undefined>));
        memberSince.value = date;
    })

    //Related to #accountOptions
    const showPwaOption = ref<boolean>(false);
    const showVerifyEmailOption = ref<boolean>(false);
    const showLinkAccountOption = ref<boolean>(false);

    if(!(window.matchMedia('(display-mode: standalone)').matches)){
      showPwaOption.value = true;
    };

    if(!userData.value.isAnonymous && userData.value.isEmailVerified === false){
      showVerifyEmailOption.value = true;
    };

    if(userData.value.isAnonymous === true){
      showLinkAccountOption.value = false; //feature not available yet.
    };

    return {
      accountImageToShow,
      userProfilePicture,
      nameToShow,
      identificationToShow,
      memberSince,
      showPwaOption,
      showVerifyEmailOption,
      showLinkAccountOption
    }
  }
})
